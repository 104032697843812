<template>
    <loading-animation v-if="DataLoading"></loading-animation>
    <div v-else class="m-3">
        <div class="fl-te-c mb-3">
            <h4>Transaction Details # 1</h4>
            <div class="btn-group">
                <btn size="sm" @click="$router.push('/sales/transactions/list/')" text="Back"/>
            </div>
        </div>
        <div class="card bs-4 p-0 round-2 of-h">
            <h5 class="bg-1 p-3">Details</h5>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-2 label">Order ID</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ details.order_id }}</div>
                <div class="col-2 label">Transaction Id</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ details.transaction_id }}</div>
                <div class="col-2 label">Order</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ details.order.id }}</div>
            </div>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-2 label">Initiated Date</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ details.initiated_date }}</div>
                <div class="col-2 label">Amount</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ details.amount }}</div>
                <div class="col-2 label">Status</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ details.status }}</div>
            </div>
            <div class="row pl-3 pt-3 pr-5 mb-3">
                <div class="col-2 label">Transaction Mode</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ details.transaction_mode }}</div>
                <div class="col-2 label">Additional Charges</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ details.additional_charges }}</div>
                <div class="col-2 label">Bank Ref No</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ details.bank_ref_num }}</div>
            </div>
        </div>
        <div class="card bs-4 p-0 round-2 of-h">
            <h5 class="bg-1 p-3">Details</h5>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-2 label">Order ID</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ details.order_id }}</div>
                <div class="col-2 label">Transaction Id</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ details.transaction_id }}</div>
                <div class="col-2 label">Order</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ details.order.id }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import urls from '../../../../data/urls';
import axios from 'secure-axios';

export default {
    name : 'DetailsComponent',
    data () {
        return {
            DataLoading    : false,
            transaction_id : this.$route.params.id,
            details        : '',
            loading        : false
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods : {
        async loadDetails () {
            const that = this;
            that.DataLoading = true;
            const response = await axios.form(urls.salesAdmin.transaction.details,
                { transaction_id : that.transaction_id });
            const json = response.data;
            that.details = json.data;
            that.DataLoading = false;
        }
    }
};
</script>

<style scoped>
.label {
    font-weight: 500;
}
</style>
